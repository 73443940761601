<template>
  <div class="m1">
    <div class="hd">
      <div class="h1">
        公司列表

      </div>
      <!-- <div class="h2">
        添加员工
      </div> -->
<div class="h2" @click="queryNotice()">施工安全提示</div>
    </div>

<!-- 新增弹窗/修改 -->
    <el-dialog title="施工安全提示" :visible.sync="noticeFormVisible">
      <el-form :model="noticeForm">
        <el-form-item  label="提示内容:" :label-width="formLabelWidth">
          <el-input  type="textarea" rows="10" autosize="true" v-model="noticeForm.content" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="noticeFormVisible = false">取 消</el-button>
        <el-button  type="primary" @click="saveNotice()">保存</el-button>
      </div>
    </el-dialog>

    <!-- 列表 -->
    <div class="lb">

      <div class="hd bg">
        <!-- 账号密码 -->
        <div>
          <span class="hd_1">
            序号
          </span>
          <span class="hd_2" style="width: 64.6%;">
            <div style="margin-left: 1rem;">
              公司名称
            </div>
          </span>
          <span class="hd_2" style="width: 9%;">
            <div style="margin-left: 1rem;">
              创建日期
            </div>
          </span>
          <span class="hd_2" style="width: 19%; text-align: center;">
            <!-- 全部<i class="el-icon-arrow-down el-icon--right"></i> -->
            状态
          </span>
        </div>
      </div>

      <!-- 主体 -->
      <div style="margin-top: 2rem;">
        <div class="main" v-for="(item,index) in Data" :key="index" @click="To(item.id)" :class="index % 2 == 0?'bg1':'bg2'">
          <div style="width: 100%;display: flex;">
            <div class="m_1">
              {{index + 1}}
            </div>
            <div class="m_2" style="width: 64.6%;">
              <div style="margin-left: 1rem;">
               {{item.projectName}}
              </div>
            </div>
            <span class="m_2" style="width: 9%;">
              <div style="margin-left: 1rem;">
               {{item.gmtCreate.substr(0,10)}}
              </div>
            </span>
            <span class="m_2" style="width: 19%; ">
              <div style="color: rgba(43, 222, 201, 0.6); text-align: center;">
                {{xmzt[item.state]}}
              </div>
            </span>
          </div>
        </div>
      </div>

    </div>

    <!-- 翻页 -->
    <div style="width: 100%; height: 5rem; padding: 1rem 0; margin-top: 4rem;">
      <!-- <span class="demonstration">完整功能</span> -->
      <el-pagination style="float: right;" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'gsgly',
    data() {
      return {
        formLabelWidth: '12rem',
        noticeFormVisible:false,
        noticeForm:{},
        xmzt:['进行中','已完成'],
        list: [{
            name: '在线检测',
            qr: false
          },
          {
            name: '项目管理',
            qr: false
          }, {
            name: '设备管理',
            qr: false
          }, {
            name: '施工管理',
            qr: false
          }, {
            name: '告警管理',
            qr: false
          }, {
            name: '账号管理',
            qr: false
          }

        ],
        value: true,
        currentPage4: 1,
        obj: {
          "page": 1,
          "size": 10
        },
        total:null,
        Data:[]
      }
    },
    created() {
      this.info()
    },
    methods: {
      queryNotice(){
        this.$newGet("/construction/notice/query").then(res=>{
            this.noticeForm = res.data
        })
        this.noticeFormVisible = true;
      },
      saveNotice(){
        this.$newPost("/construction/notice/save",this.noticeForm).then(res=>{
            if(res.success){
              this.$message({
                type:'success',
                message:"保存成功"
              })
            }else{
              this.$message({
                type:'error',
                message:"保存失败"
              })
            }
        })
        this.noticeFormVisible = true;
      },
      To(id) {
        this.$router.push('/sggl_1?id=' + id)
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.obj.size = val
        this.info()

      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.obj.page = val
        this.info()
      },
      xian(item) {
        item.qr = !item.qr
      },
      info() {
        this.$ajax('sggl', 'post', this.obj).then(res => {
          console.log(res);
          this.Data = res.data.records
          this.total = res.data.total
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .bg {
    background-color: rgba(43, 201, 222, 0.6);
  }

  .bg1 {
    background-color: rgba(162, 162, 162, 0.1);
  }

  .bg2 {
    background: rgba(14, 242, 242, 0.25);
  }
  .el-dropdown-link {
    font-size: 1.4rem;
    cursor: pointer;
    color: #FFFFFF;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .m1 {
    position: relative;
    color: #FFFFFF;
    margin: 1rem 0 0 1rem;
    width: 156.6rem;
    // background-color: #192534;

    // height: 59.4rem;

    .hd {
      position: relative;
      width: 100%;
      height: 5rem;
      .h1 {
        display: inline-block;
        width: 11.5rem;
        height: 5rem;
        line-height: 5rem;
        padding-left: 1rem;
        font-size: 1.8rem;

      }
      .set-notic-btn{
        display: flex;
      }

      .h2 {
        position: absolute;
        top: 1rem;
        right: 2rem;
        width: 11.5rem;
        height: 4.2rem;
        background-color: rgba(31, 171, 150, 8.0);
        line-height: 4.2rem;
        text-align: center;
        border-radius: .5rem;
      }
    }

    .lb {
      position: relative;
      // border: 1px solid #2BDEC9;
      margin-top: 1rem;
      font-size: 1.4rem;
      // overflow: auto;

      .hd {
        width: 100%;
        height: 3.6rem;
        line-height: 3.6rem;
        // padding-left: 1rem;
        font-size: 1.4rem;

        // border-bottom: 1px solid #2BDEC9;
        .hd_1 {
          display: inline-block;
          width: 5%;
          height: 4.2rem;
          line-height: 4.2rem;
          text-align: center;
          // margin-right: .3%;
          // background-color: rgba(43, 201, 222, 0.6);
        }

        .hd_2 {
          display: inline-block;
          width: 94.5%;
          height: 4.2rem;
          line-height: 4.2rem;
          // background-color: rgba(43, 201, 222, 0.6);
          // margin-right: .3%;
        }

        .zh {
          margin-left: 1rem;
          display: inline-block;
          width: 16.8rem;
          overflow: hidden;
        }

      }

      .hd:last-child {
        // border-bottom: none;
      }

      .qx {
        position: absolute;
        top: .1rem;
        left: 50rem;
        display: inline-block;

        .lst {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          background-color: rgba(43, 201, 222, 0.6);
          text-align: center;
          border-radius: .3rem;
          margin-right: 1rem;
        }
      }

      .xg {
        position: absolute;
        top: .5rem;
        right: 2rem;
        width: 27rem;
        height: 2.6rem;
        line-height: 2.6rem;

        .gg {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          text-align: center;
          background-color: rgba(28, 184, 184, 1);
          border-radius: .4rem;
          margin-left: 1rem;
        }
      }
    }

    .main {
      position: relative;
      width: 100%;
      // height: 4.2rem;
      // margin-top: .5rem;
      padding: .5rem 0;

      .m_1 {
        display: inline-block;
        width: 5%;
        height: 4.2rem;
        line-height: 4.2rem;
        text-align: center;
        margin-right: .3%;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
      }

      .m_2 {
        display: inline-block;
        width: 94.5%;
        height: 4.2rem;
        line-height: 4.2rem;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
        // margin-right: .3%;
      }

      .m_3 {
        position: relative;
        box-sizing: border-box;
        float: right;
        width: 94.5%;
        height: 4.2rem;
        border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
        margin-top: .5rem;
        line-height: 4.2rem;

        .zh {
          margin-left: 1rem;
          display: inline-block;
          width: 16.8rem;
          overflow: hidden;
        }

        .qx {
          position: absolute;
          top: .1rem;
          left: 50rem;
          display: inline-block;

          .lst {
            display: inline-block;
            width: 6.6rem;
            height: 2.6rem;
            line-height: 2.6rem;
            background-color: rgba(43, 201, 222, 0.6);
            text-align: center;
            border-radius: .3rem;
            margin-right: 1rem;
          }
        }

        .xg {
          position: absolute;
          top: .5rem;
          right: 2rem;
          width: 27rem;
          height: 2.6rem;
          line-height: 2.6rem;

          .gg {
            display: inline-block;
            width: 6.6rem;
            height: 2.6rem;
            line-height: 2.6rem;
            text-align: center;
            background-color: rgba(28, 184, 184, 1);
            border-radius: .4rem;
            margin-left: 1rem;
          }
        }
      }

      .m_3:last-child {
        margin-bottom: 1rem;
      }
    }
    .main:hover {
      background-color: rgba(117, 117, 117, 0.4);
    }
  }
</style>
